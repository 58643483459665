import React, { useEffect } from "react";

import HeaderMobile from "./mobile/HeaderMobile";
import Clients from "./Clients";
import Navbar from "./mobile/Navbar";
import Footer from "./Footer";
import TahapanMobile from "./mobile/TahapanMobile";
import MobileInformation from "./mobile/MobileInformation";
import Biaya from "./mobile/Biaya";

// import components



const MobileApp = () => {
  useEffect(() => {
    document.title = "Digitalisasikan Bisnis Anda!";
  });

  return (
    <div className="w-full max-w-[1560px] mx-auto bg-white">
      <Navbar />
      <HeaderMobile />
      <MobileInformation />
      <TahapanMobile/>
      <Biaya/>
      <Clients/>
      <Footer/>
    </div>
  );
};

export default MobileApp;
