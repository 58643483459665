// Import From Components Portofolio
import sidoremiImage from "./assets/portofolio/Sidoremi.png";
import internetBillingImage from "./assets/portofolio/Internet-billing.png";
import simImage from "./assets/portofolio/SIM.png";
import simpulImage from "./assets/portofolio/Simpul.png";
import goletImage from "./assets/portofolio/Golet.png";

// Import Images from About Us
import photoYudi from "./assets/about-us/yudi.png";
import photoReno from "./assets/about-us/reno.png";
import photoTio from "./assets/about-us/tio.png";
import photoAnugrah from "./assets/about-us/anugrah.png";
import photoRizky from "./assets/about-us/rizzky.png";
import photoYogi from "./assets/about-us/yogi.png";
import photoNadia from "./assets/about-us/nadia.png";
import photoVicky from "./assets/about-us/vicky.png";
import photoYola from "./assets/about-us/yola.png";

// Import Logos from Clients
import logo1 from "./assets/clients/logo1.png";
import logo2 from "./assets/clients/logo2.png";
import logo3 from "./assets/clients/logo3.png";
import logo4 from "./assets/clients/logo4.png";
import logo5 from "./assets/clients/logo5.png";
import logo6 from "./assets/clients/logo6.png";
import logo7 from "./assets/clients/logo7.svg";

import Org1Img from "./assets/Testimoni/org1.jpg";
import Org2Img from "./assets/Testimoni/org2.jpg";
import Org3Img from "./assets/Testimoni/org3.jpg";
import Org4Img from "./assets/Testimoni/org4.jpg";
import Org5Img from "./assets/Testimoni/org5.jpg";
import Org6Img from "./assets/Testimoni/org6.jpeg";

import Layanan1 from "./assets/Layanan1.svg";
import Layanan2 from "./assets/Layanan2.svg";
import Layanan3 from "./assets/Layanan3.svg";
import Gambar1 from "./assets/BNSP.png";
import Gambar2 from "./assets/dicoding-header-logo.png";
import Gambar3 from "./assets/skillacademy.png";
import Features1Img from "./assets/bg.svg";

export const testimoni = {
  title: "Testimoni",
  persons: [
    {
      avatar: <Org6Img />,
      name: "Bapak Anwar Sulistyo, S.H",
      message:
        "“ Komunikasi sangat baik. Target memenuhi harapan. Toko online saya berjalan sampai sekarang.”",
    },
    {
      avatar: <Org6Img />,
      name: "Bapak Ronald Prasetyo",
      message:
        "“ Bekerjasama dengan CV. Kodingna membantu saya merekap data data perusahaan dgn menggunakan teknologi terkini.”",
    },
    {
      avatar: <Org6Img />,
      name: "Bapak Hartono, M.M",
      message:
        "“ Pelayanan dan hubungan yg sangat ramah dan sopam. Standar sesuai dengan MoU, tampilan desain aplikasi sangat menarik.”",
    },
  ],
};

export const layanan = {
  title: "Layanan Profesional",
  title1: "Pengembangan Aplikasi",
  subtitle1:
    "Kami mengedepankan teknologi terkini dalam proses pengembangan dan pengujian aplikasi Android maupun iOS.",
  image1: <Layanan1 />,
  title2: "Pengembangan Website",
  subtitle2:
    "Kami menggunakan teknologi PHP, JavaScript, Mysql, Postgreql, mongodb, apache, nginx, NodeJs, ReactJs, Laravel & aplikasi Figma.",
  image2: <Layanan2 />,
  title3: "Konsultasi IT",
  subtitle3:
    "Anda dapat konsultasi dengan tim terkait secara GRATIS dari segi ide, budget dan konsep aplikasi maupun website.",
  image3: <Layanan3 />,
};

export const header = {
  title: "Jasa Pembuatan",
  title2: "Aplikasi Mobile & Website",
  title3: "Digitalisasikan Bisnis Anda Sekarang Juga!",
};

export const headerweb = {
  title: "Jasa Pembuatan Website (Custom)",
  title2: "Profesional & Terpercaya",
};

export const headermobile = {
  title: "Jasa Pembuatan Aplikasi Android & iOS",
  title2: "Profesional & Terpercaya",
};

export const tahapanmobile = {
  title: "Tahapan Pengembangan Aplikasi",
  text1:
    "Pada tahap pengembangan, tim developer dari Kodingna.com menggunakan sistem SDLC ( Software Development Lifecycyle )  metode Agile. Agile adalah metode yang diperkenalkan pada tahun 2001 oleh sekelompok orang dalam komunitas pengembang. ",
  text2:
    "Agile development dikembangkan untuk memperbaharui metode pengembangan tradisional yang dikenal dengan metode waterfall atau spiral. ",
  text3:
    "Diyakini metode ini dapat mempercepat proses pengembangan suatu produk",
};

export const tahapanweb = {
  title: "Tahapan Pengembangan website",
  text1:
    "Pada tahap pengembangan, tim developer dari Kodingna.com menggunakan sistem SDLC ( Software Development Lifecycyle )  metode Agile. Agile adalah metode yang diperkenalkan pada tahun 2001 oleh sekelompok orang dalam komunitas pengembang. ",
  text2:
    "Agile development dikembangkan untuk memperbaharui metode pengembangan tradisional yang dikenal dengan metode waterfall atau spiral. ",
  text3:
    "Diyakini metode ini dapat mempercepat proses pengembangan suatu produk",
};

export const biaya = {
  title: "Rincian Biaya Pembuatan Website",
  text1:
    "Sebagai dasar acuan, kami menargetkan beberapa ketentuan untuk dasar biaya. Dalam perhitungan “jumlah pekerja X waktu pengerjaan”. Semakin banyak fungsi element yang diminta oleh klien, semakin banyak tenaga kerja dan biaya yang dibutuhkan dalam pengembangan website. ",
  text2:
    "Jangan Ragu ! Anda dapat konsultasi dengan tim terkait secara GRATIS dari segi ide, budget dan konsep aplikasi.  ",
  text3:
    "Klik disini untuk menghubungi tim developer via Whatsapp :0821-5218-7313",
};

export const biayamob = {
  title: "Rincian Biaya Pembuatan Aplikasi",
  text1:
    "Sebagai dasar acuan, kami menargetkan beberapa ketentuan untuk dasar biaya. Dalam perhitungan “jumlah pekerja X waktu pengerjaan”. Semakin banyak fungsi element yang diminta oleh klien, semakin banyak tenaga kerja dan biaya yang dibutuhkan dalam pengembangan website. ",
  text2:
    "Jangan Ragu ! Anda dapat konsultasi dengan tim terkait secara GRATIS dari segi ide, budget dan konsep aplikasi.  ",
  text3:
    "Klik disini untuk menghubungi tim developer via Whatsapp :0821-5218-7313",
};

export const sertifikasi = {
  image: <Features1Img />,
  title: "Developer Profesional dan Berpengalaman",
  subtitle:
    "Semua tim developer telah teruji secara sistematis maupun teknis dalam pengembangan, pengujian hingga pemeliharaan perangkat lunak serta bersertifikat tingkat Nasional",
  subtitle2:
    "selalu memberikan solusi teknologi terbaik untuk dapat memahami beragam kebutuhan demi tercapainya kepuasan optimal bagi klien.",
  image1: <Gambar1 />,
  image2: <Gambar2 />,
  image3: <Gambar3 />,
};

export const PortofolioImage = {
  // Portofolio
  dataPortofolio: [
    {
      id: 1,
      bgImage: sidoremiImage,
      title: "Sidoremi",
      desc: "Aplikasi Donor Darah Khusus Talasemia (SiDoReMi) merupakan sebuah aplikasi yang menghubungkan pendonor yang ingin menggunakan layanan donor darah untuk anak-anak Talasemi.",
    },
    {
      id: 2,
      bgImage: internetBillingImage,
      title: "Internet-Billing",
      desc: "Tools system Internet-Billing merupakan aplikasi pengelolaan manajemen penyediaan internet. Technologies Used : Python dan PHP.",
    },
    {
      id: 3,
      bgImage: simImage,
      title: "S.I.M",
      desc: " Sistem Informasi Monitoring merupakan website yang digunakan untuk mengelola tiket gangguan agar berjalan dengan optimal. Technoloies used : Codeigniter 3 dan MySQL",
    },
    {
      id: 4,
      bgImage: simpulImage,
      title: "Simpul",
      desc: "Website Simpul adalah website yang digunakan untuk melakukan voting secara online dan realtime. Technologies used : Codeigniter 3, MySQL.",
    },
    {
      id: 5,
      bgImage: goletImage,
      title: "Golet",
      desc: "Aplikasi Golet adalah aplikasi untuk manajemen parkir secara elektronik. Sistem ini terintegrasi dengan semua bidang/petugas terkait serta alat pengawasan",
    },
  ],
};

export const AboutUsImage = {
  photos: [
    { id: 1, url: photoYudi, name: "Yudi Zidane", title: "Founder" },
    {
      id: 2,
      url: photoReno,
      name: "Reno Agil Saputra",
      title: "Creative Developer",
    },
    { id: 3, url: photoTio, name: "Tio Fani", title: "Full Stack Developer" },
    { id: 4, url: photoAnugrah, name: "Anugrah Iman", title: "System Analyst" },
    {
      id: 5,
      url: photoRizky,
      name: "Rizky Pambudi",
      title: "Flutter Developer",
    },
    {
      id: 6,
      url: photoYogi,
      name: "Yogi Pristiawan",
      title: "Backend Developer",
    },
    { id: 7, url: photoNadia, name: "Nadia Sri", title: "Counselor" },
    {
      id: 8,
      url: photoVicky,
      name: "Vicky Herdiansyah Adri",
      title: "Frontend Developer",
    },
    {
      id: 9,
      url: photoYola,
      name: "Febryola Kurnia Putri",
      title: "Frontend Developer",
    },
  ],
};

export const ClientsLogo = {
  logos: [
    {
      id: 1,
      url: logo1,
    },
    {
      id: 2,
      url: logo2,
    },
    {
      id: 3,
      url: logo3,
    },
    {
      id: 4,
      url: logo4,
    },
    {
      id: 5,
      url: logo5,
    },
    {
      id: 6,
      url: logo6,
    },
    {
      id: 7,
      url: logo7,
    },
  ],
};
