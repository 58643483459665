import React from 'react'
import {tahapanweb} from '../../data'
import agile from '../../assets/agile.png'
const TahapanWeb = ()=> {
    const{title,text1,text2,text3}= tahapanweb;
  return (
    <section className='metode  w-full bg-gray-50 '>
      <div className='container mx-auto '>
        <h2 className='title text-center font-bold  md:text-4xl py-4 text-sky-800 text-2xl   lg:leading-tight lg:max-w-[888px] mx-auto z-[1] mb-[0px] relative'>{title}</h2>
        <img className='w-[300px] lg:w-[780px] mx-auto' src={agile} alt='tahapan-pengembangan-web'/>
        <p className="md:text-[18px] md:leading-7 leading-5 text-xs text-center my-2 text-gray-800 font-medium">
            {text1}
        </p>
        <p className="md:text-[18px] md:leading-7 leading-5 text-xs text-center my-2 text-gray-800 font-medium"><span className="text-sky-700 font-semibold">{text2}</span>
              {text3}</p>
      </div>
    </section>
  )
}

export default TahapanWeb