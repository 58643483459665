import { PortofolioImage } from "../data";

import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Portofolio = () => {
  const { dataPortofolio } = PortofolioImage;
  return (
    <section id='portofolio' className='portofolio'>
    <div className="bg-gray-50 pt-8">
      <div className="max-w-5xl mx-auto p-5">
        <div className="flex flex-col items-center max-w-5xl mx-auto">
          <h2 className="md:text-5xl py-4 text-sky-800 font-bold text-4xl">
            Portofolio
          </h2>
          <p className="md:text-xl text-xs text-center my-2 text-gray-800">
            <span className="text-sky-700 font-semibold">Kodingna.com</span>{" "}
            telah menyelesaikan berbagai macam project, dari pengembangan
            aplikasi mobile, website bahkan tools system perusahaan.
          </p>
        </div>
        <div className="max-w-5xl m-auto">
          <Swiper
            // install Swiper modules
            modules={[Pagination]}
            loop={true}
            pagination={{ clickable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
            className="md:h-[400px] h-[350px]"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 18,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 4,
              },
            }}
          >
            {dataPortofolio.map((data, index) => {
              const { bgImage, title, desc } = data;
              return (
                <SwiperSlide key={index}>
                  <div className="mx-10">
                    <div className="md:w-72 md:h-80  border rounded-lg relative z-10 my-4  w-60 h-72 max-w-[280px] text-left mx-auto">
                      <img
                        src={bgImage}
                        alt=""
                        className="w-full h-full rounded-lg object-cover absolute mx-auto"
                      />
                      <div className="md:mt-44 flex flex-col items-center mt-36 mx-5 text-white">
                        <h3 className="font-bold text-2xl z-10 ">{title}</h3>
                        <p className="md:text-xs md:leading-[1.15rem] text-[11px] z-10">
                          {desc}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Portofolio;
