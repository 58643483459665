import React from 'react';
import {useState,useEffect} from 'react';
// import data
import Instagram from "../../assets/footer/instagram.png";
import Linkedin from "../../assets/footer/linkedin.png";


const Konsul = () => {
  const [formErrors, setFormErrors] = useState({});
  const [keperluan, setKeperluan] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setFormData] = useState({
    nama: "",
    nomor: "",
    perusahaan: "",
    pesan: "",
  });

  const { nama, nomor, perusahaan, pesan } = formData;

  const onChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formData);
    }
  }, [formErrors]);

  const onSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formData));
    setIsSubmit(true);
            
  };

  const validate = () => {
    const errors = {};
    const regex = /[0-9]/;
    if (!nama) {
      errors.nama = "Nama Lengkap is required!";
    } else if (!nomor) {
      errors.nomor = "Nomor Telepon is required!";
    } else if(!regex.test(nomor)){
      errors.nomor = "This is not a valid number format!";
    }else if(nomor.length<10){
      errors.nomor = "This is not a valid number format!";
    }else if (!perusahaan) {
      errors.perusahaan = "Nama Perusahaan is required!";
    } else if(!keperluan){
      errors.keperluan = "Keperluan is required!";
    } else if (!pesan) {
      errors.pesan = "Pesan is required!";
    }
     else {
            
              // Appending the phone number to the URL
              let url = `https://web.whatsapp.com/send?phone=${+6282152187313}`;
              // Appending the message to the URL by encoding it
                url += `&text=${encodeURI("Halo, saya "+ nama +" dari " + perusahaan + ' ingin bertanya tentang ' + keperluan + ' yaitu ' + pesan)}&app_absent=0`;
              // Open our newly created URL in a new tab to send the message
                window.open(url);
    }
    return errors;
  };

  return (
    <section className='w-full bg-primary bg-cover bg-no-repeat text-white  '>
        <div className='container mx-auto'>

            {/*box*/}
            <form onSubmit={onSubmit}>
              <div className='pt-[120px] items-center flex  flex-col lg:flex-row '>
                  <div className='p-[25px] lg:p-[40px] m-auto lg:mr-[0px] rounded-[11px] bg-bluet w-[300px] lg:w-[357px] '>
                      <div className='grid grid-cols-2 gap-[10px] mx-auto'>
                          <p className='text-[8px] lg:text-[10px] mb-[10px]'>Nama Lengkap</p>
                          <p className='text-[8px] lg:text-[10px]'>No.Telepon(WhatsApp)</p>
                      </div>
                      <div className='grid grid-cols-2 gap-[10px] mx-auto'>
                          <input type="text" name='nama' onChange={onChange} value={nama} required className=' border text-[10px] outline-none bg-bluet border-border w-[120px] lg:w-[133px] h-[25px] lg:h-[30px]'/>
                          <input type="tel" pattern="[0-9]*" onChange={onChange} name='nomor' value={nomor} required className='border text-[10px] outline-none bg-bluet border-border w-[120px] lg:w-[133px] h-[25px] lg:h-[30px]'/>
                          <p className='text-[8px] lg:text-[10px]  text-white'>{formErrors.nama}</p>
                          <p className='text-[8px] lg:text-[10px]  text-white'>{formErrors.nomor}</p>
                      </div>
                      <div className='mt-[20px] mx-auto'>
                          <p className='text-[8px] lg:text-[10px] mb-[10px]'>Nama Perusahaan / Unit Usaha / Lembaga</p>
                          <input type="text" name='perusahaan' onChange={onChange} value={perusahaan} required className='border text-[10px] outline-none bg-bluet border-border w-[250px] lg:w-[277px] h-[25px] lg:h-[30px]'/>
                          <p className='text-[8px] lg:text-[10px]  text-red-600'>{formErrors.perusahaan}</p>
                      </div>
                      <div className='mt-[20px] mx-auto'>
                          <p className='text-[8px] lg:text-[10px] mb-[10px]'>Keperluan</p>
                          <select name='keperluan'
                          required
                          value={keperluan}
                          onChange={(e)=>{
                            const keperluan = e.target.value;
                            setKeperluan(keperluan);
                          }} 
                          className='border text-[10px] outline-none bg-bluet border-border w-[250px] lg:w-[277px] h-[25px] lg:h-[30px]'>
                              <option value="Pilih Keperluan Anda">Pilih Keperluan Anda</option>
                              <option value="Pengembangan Aplikasi Android">Pengembangan Aplikasi Android</option>
                              <option value="Pembuatan Webstie ( Custom )">Pembuatan Webstie ( Custom )</option>
                              <option value="Pembuatan Aplikasi Mobile ( Android / iOS )">Pembuatan Aplikasi Mobile ( Android / iOS )</option>
                              <option value="Jasa Maintenance Website">Jasa Maintenance Website</option>
                              <option value="Jasa Maintenance Aplikasi Mobile ( Android / iOS )">Jasa Maintenance Aplikasi Mobile ( Android / iOS )</option>
                              <option value="Penawaran kerja sama">Penawaran kerja sama</option>
                              <option value="Peluang Karir">Peluang Karir</option>
                              <option value="Lainnya">Lainnya</option>
                          </select>
                          <p className='text-[8px] lg:text-[10px]  text-red-600'>{formErrors.keperluan}</p>
                      </div>
                      <div className='mt-[20px] mx-auto'>
                          <p className='text-[8px] lg:text-[10px] mb-[10px]'>Tuliskan Spesifikasi Permintaan Anda Disini...</p>
                          <textarea name='pesan' value={pesan} onChange={onChange} required className='border resize-none p-[5px] text-[10px] outline-none bg-bluet border-border w-[250px] lg:w-[277px] h-[70px] lg:h-[90px]'/>
                          <p className='text-[8px] lg:text-[10px]  text-red-600'>{formErrors.pesan}</p>
                      </div>
                      <button type='submit' onClick={onSubmit} className='bg-white w-[250px] lg:w-[277px] rounded-[20px] h-[30px] lg:h-[40px]  mt-[20px]'><p className='font-bold text-blue text-center lg:py-[8px]'>Kirim</p></button>
                  </div>
                  {/* text */}
                  <div className='text-center mt-[20px] lg:mt-[0px] lg:text-left justify-end order-2 lg:-order-1 font-serif text-[12px] lg:text-[16px] '>
                      <ul className='mb-10'>
                        <li>Kontak kami:</li>
                        <li>Whatsapp  : 0821-5218-7313</li>
                        <li>Email     : halo@kodingna.com</li>
                        <li>Instagram : kodingnaindonesia</li>
                      </ul>
                      <ul className=''>
                        <li>Kantor terdaftar:</li>
                        <li>CV.Kodingna Inovasi Teknologi</li>
                        <li>SKT.Kemenkumham AHU-0048960-AH.01.14 Tahun 2022</li>
                        <li>Jl H Rais A Rahman Gang Hasia No-A25</li>
                        <li>Pontianak - Kalimantan Barat 78115</li>
                      </ul>
                  </div>
              </div>
            </form>
            <div className='lg:mt-6 mt-5'>
            <div className="max-w-5xl mx-auto flex justify-between items-center px-10 py-5">
            <p className="md:text-sm text-[9px] text-white font-medium">
              &copy;2022 Kodingna.com powered by CV. Kodingna Inovasi Teknologi,
              <span className="italic"> All rights reserved</span>
            </p>
            <div className="md:basis-1/3">
              <div className="flex justify-end">
                <a href="https://www.instagram.com/kodingnaind/" target="_blank" rel="noreferrer">
                  <img
                    src={Instagram}
                    alt=""
                    className="md:w-[25px] md:h-[25px] w-[20px] h-[20px] md:mx-2 mx-4 hover:bg-slate-800 hover:rounded-lg"
                  />
                </a>
                <a href="https://www.linkedin.com/company/kodingnaindonesia/" target="_blank" rel="noreferrer">
                  <img
                    src={Linkedin}
                    alt=""
                    className="md:w-[25px] md:h-[25px] w-[20px] h-[20px] md:mx-2 mx-4 hover:bg-slate-800"
                  />
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
    </section>
  );
};

export default Konsul;
