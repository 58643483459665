import React from "react";

import { AboutUsImage } from "../data";

import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const AboutUs = () => {
  const { photos } = AboutUsImage;

  return (
    <section id='tentangkami' className="tentangkami bg-gray-50">
      <div className="bg-aboutus bg-center bg-white bg-cover md:pt-20 md:pb-10 bg-no-repeat md:bg-local bg-fixed relative">
        <h2 className="mx-auto md:text-5xl py-4 text-sky-800 font-bold text-4xl text-center">
          Tentang Kami
        </h2>
        <p className="md:text-[15px] md:leading-7 leading-5 text-xs text-center text-gray-800 font-medium max-w-5xl mx-auto p-5">
          <span className="">Kodingna.com</span> merupakan startup yang bergerak
          di bidang software house yang menawarkan jasa konsultasi IT seperti
          pengembangan aplikasi Android, iOS & Website (Custom). Tim
          Kodingna.com memiliki pengalaman dalam proses pengembangan, pengujian
          hingga pemeliharaan perangkat lunak. Dengan pengalaman tersebut, kami
          yakin dapat membantu serta memberikan solusi teknologi yang tepat
          untuk menyelesaikan berbagai masalah dan selalu menjaga komunikasi
          yang baik serta kepercayaan dari Klien.
        </p>
        <div className="max-w-5xl mx-auto">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination]}
            navigation
            loop={true}
            pagination={{ clickable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
            className="h-[260px]"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 18,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 4,
              },
            }}
          >
            {photos.map((photo, index) => {
              const { url, name, title } = photo;
              return (
                <SwiperSlide key={index}>
                  <div className="flex items-center flex-col text-center">
                    <img src={url} alt="" className="w-36" />
                    <div className="md:py-5 py-3">
                      <h3 className="font-semibold">{name}</h3>
                      <p>{title}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
        <div className="max-w-5xl mx-auto">
          <div className="md:flex md:flex-row flex-col justify-center flex-wrap">
            <div className="basis-[40%] m-5 ">
              <h2 className="md:text-5xl py-4 text-sky-800 font-bold text-3xl text-center">
                Visi
              </h2>
              <div className="md:text-[15.5px] md:leading-6 leading-5 p-4 border border-current rounded-lg text-gray-800 text-justify text-xs bg-white">
                <p>
                  Menjadi sarana pengembangan, konsultasi, dan informasi di
                  bidang teknologi untuk membantu meningkatkan kualitas di
                  industri digital saat ini, dengan menerapkan teknologi kami
                  berharap dapat membantu mengembangkan berbagai lini bisnis
                  seperti perusahaan, individu, UMKM dan institusi.
                </p>
              </div>
            </div>
            <div className="basis-[40%] m-5">
              <h2 className="md:text-5xl py-4 text-sky-800 font-bold text-3xl text-center">
                Misi
              </h2>
              <div className="md:text-[15px] md:leading-6 leading-5 p-4 border border-current rounded-lg text-gray-800 text-justify text-xs bg-white">
                <ul>
                  <li>
                    1. Membangun perusahaan yang unggul di bidang teknologi dan
                    solusi bagi lini perusahaan, perseorangan maupun lembaga.
                  </li>
                  <li>
                    2. Memahami beragam kebutuhan dan memberikan layanan
                    teknologi yang tepat demi tercapainya kepuasan optimal bagi
                    klien.
                  </li>
                  <li>3. Meningkatkan nilai stakeholder Kodingna.com</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-5">
            <h2 className="md:text-5xl pb-5 text-sky-800 font-bold text-3xl text-center">
              Gambaran Umum
            </h2>
            <p className="md:text-[15px] md:leading-7 leading-5 text-xs text-center my-2 text-gray-800 font-medium">
              <span className="text-sky-700 font-semibold">Kodingna.com</span>{" "}
              pertama kali diinisiasi pada tanggal 11 Februari 2021 oleh Yudi
              Zidane selaku Founder yang berorientasi pada Business Development
              dan Reno Agil Saputra selaku Co-Founder yang berperan sebagai
              Creative Developer. Operasional perdana{" "}
              <span className="text-sky-700 font-semibold">Kodingna.com</span>{" "}
              pada tanggal 11 Desember 2021 dengan penambahan tim yaitu, Anugrah
              Iman Setyo Utomo sebagai System Analyst dan Tio Fani sebagai Lead
              Developer. Pada tahun 2022, Kodingna.com resmi menjadi sebuah
              badan usaha dibawah naungan{" "}
              <span className="text-sky-700 font-semibold">
                CV. Kodingna Inovasi Teknologi,
              </span>{" "}
              yang telah berlisensi pada Kemenkumham.
            </p>
          </div>
        </div>
    </section>
  );
};

export default AboutUs;
