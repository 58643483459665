import React from 'react';

// import data
import { header } from '../data';

// import components
import stats from '../assets/wave.svg';

const Header = () => {
  const { title, title2, title3} = header;
  return (
    <section id='header'  className='header h-[100vh] max-h-[850px] w-full bg-home bg-right lg:bg-center bg-cover bg-no-repeat text-white pt-[225px] pb-[254px] relative '>
      <div className='container mx-auto text-center'>
        {/* title */}
        <h1 className='text-2xl lg:text-[40px] lg:leading-tight lg:max-w-[888px] mx-auto font-semibold mb-[10px]'>
          {title}
        </h1>
        {/* title */}
        <h1 className='text-2xl lg:text-[40px] lg:leading-tight lg:max-w-[888px] mx-auto font-semibold mb-[10px]'>
          {title2}
        </h1>
        {/* title */}
        <h1 className='text-2xl lg:text-[40px] lg:leading-tight lg:max-w-[888px] mx-auto font-semibold mb-[30px]'>
          {title3}
        </h1>
      
        <img className='w-full overflow-hidden absolute m-auto left-0 right-0 bottom-0'src={stats} alt ='no internet connection'/>
      </div>
    </section>
  );
};

export default Header;
