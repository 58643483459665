// Import Logo
import Logo from "../assets/footer/kodingna-logo.png";
import Map from "../assets/footer/map.png";
import Instagram from "../assets/footer/instagram.png";
import Linkedin from "../assets/footer/linkedin.png";

const Footer = () => {
  return (
    <section id='kontak' className='kontak'>
    <div>
      <div className="bg-gray-200">
        <div className="max-w-5xl mx-auto p-5">
          <div className="flex justify-between flex-wrap">
            <div className="mx-auto">
              <a href="https://goo.gl/maps/dD5HebHiAs86NZxv9" target="_blank" rel="noreferrer">
                <img src={Logo} alt="" className="md:w-[250px] w-full py-3" />
                <img
                  src={Map}
                  alt=""
                  className="md:flex hidden md:w-[250px] w-[300px] mx-auto my-3"
                />
              </a>
            </div>
            <div className="mx-auto">
              <h3 className="md:text-3xl md:py-4 md:text-left text-center py-1 text-sky-800 font-semibold text-xl">
                Contact Us
              </h3>
              <div className="md:text-sm md:py-2 md:text-left text-center text-gray-800 text-xs mt-3">
                <ul>
                  <li>Whatsapp : +(62) 81253524414</li>
                  <li>Email : halo@kodingna.com</li>
                  <li>Instagram : halokodingna</li>
                </ul>
                <br />
                <p>Kantor Terdaftar :</p>
                <p>CV. Kodingna Inovasi Teknologi</p>
                <p>Jl H Rais A Rahman Gang Hasia No-A25</p>
                <p>Pontianak - Kalimantan Barat 78115</p>
                <p>Indonesia</p>
              </div>
            </div>
            <div className="mx-auto">
              <h3 className="md:text-3xl md:py-4 md:text-left text-center py-1 text-sky-800 font-semibold text-xl">
                Layanan
              </h3>
              <div className="md:text-sm md:py-2 md:text-left text-center text-gray-800 text-xs mt-3">
                <ul>
                  <li>Pengembangan Aplikasi Android</li>
                  <li>Pembuatan Website ( Custom )</li>
                  <li>Pembuatan Aplikasi Mobile ( Android / iOS )</li>
                  <li>Jasa Maintenance Website </li>
                  <li>Jasa Maintenance Aplikasi Mobile ( Android / iOS )</li>
                  <li>Penawaran kerja sama</li>
                  <li>Peluang Karir</li>
                  <br />
                  <li>Lainnya</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-sky-800">
          <div className="max-w-5xl mx-auto flex justify-between items-center px-10 py-5">
            <p className="md:text-sm text-[9px] text-white font-medium">
              &copy;2022 Kodingna.com powered by CV. Kodingna Inovasi Teknologi,
              <span className="italic"> All rights reserved</span>
            </p>
            <div className="md:basis-1/3">
              <div className="flex justify-end">
                <a href="https://www.instagram.com/kodingnaind/" target="_blank" rel="noreferrer">
                  <img
                    src={Instagram}
                    alt=""
                    className="md:w-[25px] md:h-[25px] w-[20px] h-[20px] md:mx-2 mx-4 hover:bg-slate-800 hover:rounded-lg"
                  />
                </a>
                <a href="https://www.linkedin.com/company/kodingnaindonesia/" target="_blank" rel="noreferrer">
                  <img
                    src={Linkedin}
                    alt=""
                    className="md:w-[25px] md:h-[25px] w-[20px] h-[20px] md:mx-2 mx-4 hover:bg-slate-800"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Footer;
