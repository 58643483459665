import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
// import logo
import logo from '../assets/Logo/Logo berwarna/logo4.svg';
import logoWhite from '../assets/Logo/logo1.svg';
// import icons
import { CgMenuRight, CgClose } from 'react-icons/cg';
// import data



const Header = () => {
  const [bg, setBg] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState(logo)
  useEffect(() => {
    // add event listener
    window.addEventListener('scroll', () => {
      // when scrollY is bigger than 50px setBg to true, else false
      return window.scrollY > 50 ? setBg(true) : setBg(false);
    });
  });
    //logo scroll function
    const changeLogo = () => {
      if (window.scrollY >= 50) {
      setNavbarLogo(logoWhite)
      } else {
      setNavbarLogo(logo)
      }
  }

  useEffect(() => {
      changeLogo()
      // adding the event when scroll change Logo
      window.addEventListener("scroll", changeLogo)
  })
  return (
    <header
      className={`${
        // if bg is true
        bg
          ? 'bg-primary py-4 lg:py-6'
          : // if bg is false
            'bg-none'
      }
      fixed left-0 py-8 z-10 w-full transition-all duration-200`}
    >
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          {/* logo */}
          <a href='https://kodingna.com/'>
            <img className='h-12 lg:h-14' src={navbarLogo} alt='' />
          </a>
          {/* menu icon */}
          <div
            onClick={() => setMobileNav(!mobileNav)}
            className='md:hidden text-2xl lg:text-3xl font-semibold text-white cursor-pointer'
          >
            {mobileNav ? <CgClose /> : <CgMenuRight />}
          </div>
          {/* nav */}
          <nav className='hidden md:flex font-semibold lg:text-[18px]'>
            <ul className='md:flex md:gap-x-12'>
              <li className='capitalize text-white hover:border-b cursor-pointer transition-all'><Link to="layanan" smooth={true} duration={500}>Layanan</Link></li>
              <li className='capitalize text-white hover:border-b cursor-pointer transition-all'><Link to="portofolio" smooth={true} duration={500}>Portofolio</Link></li>
              <li className='capitalize text-white hover:border-b cursor-pointer transition-all'><Link to="tentangkami" smooth={true} duration={500}>Tentang Kami</Link></li>
              <li className='capitalize text-white hover:border-b cursor-pointer transition-all'><Link to="kontak" smooth={true} duration={500}>Kontak</Link></li>
            </ul>
          </nav>
          {/* nav mobile */}
          <div
            className={`${
              mobileNav ? 'left-0' : '-left-full'
            }  fixed bottom-0 w-full max-w-xs h-screen transition-all`}
          >
            <nav className='bg-white shadow-2xl w-full h-full'>
              <ul className='text-center h-full flex flex-col items-center justify-center gap-y-6'>
                <li className='text-xl font-medium capitalize'><Link to="layanan" smooth={true} offset={-100} duration={500}>Layanan</Link></li>
                <li className='text-xl font-medium capitalize'><Link to="portofolio" smooth={true} offset={-100} duration={500}>Portofolio</Link></li>
                <li className='text-xl font-medium capitalize'><Link to="tentangkami" smooth={true} offset={-100} duration={500}>Tentang Kami</Link></li>
                <li className='text-xl font-medium capitalize'><Link to="kontak" smooth={true} offset={-100} duration={500}>Kontak</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
