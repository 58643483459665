import React from "react";

import AndroidPhoto from "../../assets/android-photo.png";
import IosPhoto from "../../assets/ios-photo.png";

const MobileInformation = () => {
  return (
    <div className="manfaat bg-gray-50">
      <div className="">
        <div className="max-w-5xl mx-auto px-5 md:pb-10">
          <h2 className="mx-auto md:text-4xl py-4 text-sky-800 font-bold text-xl text-center">
            Jasa Pembuatan Android & iOS
          </h2>
          <p className="md:text-xl text-[11px] text-center md:my-2 text-gray-800">
            Kami mengedepankan kepercayaan dari Klien dengan memahami berbagai
            keluhandan memberikan solusi teknologi yang tepat untuk
            menyelesaikan semua masalah
          </p>
        </div>
        <div className="max-w-5xl mx-auto p-5 relative z-10">
          <h2 className="mx-auto md:text-4xl py-4 text-sky-800 font-bold text-xl text-center">
            Aplikasi Android & iOS ( Custom )
          </h2>
          <p className="md:text-xl text-[11px] text-center md:my-2 text-gray-800">
            Saat ini, hampir seluruh pelaku bisnis berlomba-lomba membuat
            perusahaan mereka dikenal oleh banyak orang dengan menggunakan
            sarana di dunia digital.
          </p>
        </div>
        <div className="bg-bgManfaat bg-cover bg-no-repeat relative md:bottom-16 z-0 w-full md:pb-44 pb-16">
          <div className="max-w-5xl mx-auto p-5">
            <div className="flex flex-col items-center">
              <img src={AndroidPhoto} alt="" className="md:w-[24rem] w-[14rem] md:pt-44 pt-12" />
              <p className="px-4 font-medium md:text-[19px] text-[12px] text-justify text-gray-800">
                Pada era digitial saat ini, banyak sektor unit usaha bahkan
                lembaga menggunakan perangkat mobile melalui aplikasi android
                maupun iOS. Karena dianggap mampu untuk mengurangi kegiatan
                dalam menjalankan tugas.
              </p>
            </div>
            <div className="flex flex-col items-center">
              <img src={IosPhoto} alt="" className="md:w-[30rem] w-[18rem] md:pt-12 pt-5 "/>
              <p className="px-4 font-medium md:text-[19px] text-[12px] text-justify pb-6 my-3 text-gray-800">
                Tak hanya Android yang dikembangkan oleh Google, aplikasi iOS
                untuk iPhone dan iPad juga dikembangkan oleh Apple inc. Selain
                Android, kami juga menawarkan jasa pembuatan aplikasi iOS dan
                banyak dilirik oleh masyarakat khususnya dibidang bisnis dan
                menargetkan sistem marketing dalam strategi bisinis mereka.
              </p>
              <p className="px-4 font-medium md:text-[19px] text-[12px] text-justify text-gray-800">
                <span className="text-sky-800 font-semibold">Kodingna.com</span> hadir untuk
                membantu serta memberikan jasa yang sangat baik dan profesional
                dalam pengembangan aplikasi berbasis Android maupun iOS dengan
                menerapkan teknologi masa kini.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileInformation;
