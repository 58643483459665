import React from 'react';

// import data
import { headerweb } from '../../data';

// import components
import stats from '../../assets/wave.svg';

const HeaderWeb = () => {
  const { title, title2} = headerweb;
  return (
    <section id='headerweb' className='h-[100vh] max-h-[850px] w-full bg-mobile bg-center lg:bg-center bg-cover bg-no-repeat text-white pt-[260px] pb-[250px] '>
      <div className='container m-auto text-center '>
        {/* title */}
        <h1 className='text-2xl lg:text-[40px] lg:leading-tight lg:max-w-[888px] mx-auto font-semibold mb-[10px]'>
          {title}
        </h1>
        {/* title */}
        <h1 className='text-2xl lg:text-[40px] lg:leading-tight lg:max-w-[888px] mx-auto font-semibold '>
          {title2}
        </h1>

        <img className='w-full overflow-hidden absolute m-auto left-0 right-0 bottom-0'src={stats} alt ='no internet connection'/>
      </div>
    </section>
  );
};

export default HeaderWeb;
