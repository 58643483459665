import React, { useEffect } from "react";

import HeaderWeb from "./web/HeaderWeb";
import Clients from "./Clients";
import Navbar from "./web/Navbar";
import Footer from "./Footer";
import TahapanWeb from "./web/TahapanWeb";
import WebInformation from "./web/WebInformation";
import Biaya from "./web/Biaya";



const WebApp = () => {
  useEffect(() => {
    document.title = "Digitalisasikan Bisnis Anda!";
  });

  return (
    <div className="w-full max-w-[1560px] mx-auto bg-white">
      <Navbar />
      <HeaderWeb />
      <WebInformation />
      <TahapanWeb/>
      <Biaya/>
      <Clients/>
      <Footer/>
    </div>
  );
};

export default WebApp;
