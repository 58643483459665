import React from 'react';

// import data
import { sertifikasi } from '../data';

const Sertification = () => {
  const { title, subtitle, subtitle2, image, image1, image2, image3 } = sertifikasi;
  return (
    <section className='section  w-full bg-white-2 '>
      <div className='container mx-auto '>
        <div className='flex-1 flex flex-col justify-end '>
            <h2 className='title text-center text-blue text-2xl lg:text-[40px] lg:leading-tight lg:max-w-[888px] mx-auto mb-[20px] '>{title}</h2>
            <p className='subtitle text-center text-[16px] lg:text-[20px] lg:leading-tight lg:max-w-[700px] mx-auto mb-[15px] '>{subtitle}</p>
            <div className='flex  flex-col lg:flex-row lg:gap-x-[30px] items-center mx-auto'>
              <img className='w-[130px] h-[50px] mb-[10px]' src={image1.type} alt='' />
              <img className='w-[180px] h-[50px] mb-[10px]' src={image2.type} alt='' />
              <img className='w-[130px] h-[60px] mb-[10px]' src={image3.type} alt='' />
            </div>
        </div>
        <div className='flex items-center flex-col lg:flex-row lg:gap-x-[100px]'>
          {/* image */}
          <div className='flex-1 mb-[15px]'>
            <img src={image.type} alt='' />
          </div>
          {/* text */}
          <div className='flex-1 flex flex-col justify-end order-1 lg:-order-1 '>
            <p className='subtitle2 text-[16px] lg:text-[20px] lg:max-w-[500px]'><span className='text-primary font-semibold'>Kodingna </span>{subtitle2}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sertification;
