import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import Components
import WebApp from "./components/WebApp";
import MobileApp from "./components/MobileApp";
import KonsultasiIT from "./components/KonsultasiIT";
import Main from "./components/Main";
const App = () => {
  useEffect(() => {
    document.title = "Digitalisasikan Bisnis Anda!";
  });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/mobile" element={<MobileApp />} />
        <Route path="/web" element={<WebApp />} />
        <Route path="/konsultasi" element={<KonsultasiIT />} />
      </Routes>
    </Router>
  );
};

export default App;
