import React from "react";

const Information = () => {
  return (
    <div className="manfaat bg-gray-50">
      <div className="">
        <div className="max-w-5xl mx-auto px-5 md:pb-10 md:mt-0 mt-6">
          <h2 className="mx-auto md:text-4xl py-4 text-sky-800 font-bold text-xl text-center">
            Jasa Pembuatan Website
          </h2>
          <p className="md:text-xl text-[11px] text-center md:my-2 text-gray-800">
            Kami mengedepankan kepercayaan dan Klien dengan memahami berbagai
            keluhan dan memberikan solusi teknologi yang tepat untuk
            menyelesaikan semua masalah
          </p>
        </div>
        <div className="max-w-5xl mx-auto p-5 relative z-10">
          <h2 className="mx-auto md:text-4xl py-4 text-sky-800 font-bold text-xl text-center">
            Website Perusahaan atau Profil Bisnis ( Custom )
          </h2>
          <p className="md:text-xl text-[11px] text-center md:my-2 text-gray-800">
            Saat ini, hampir seluruh pelaku bisnis berlomba-lomba membuat
            perusahaan mereka dikenal oleh banyak orang dengan menggunakan
            sarana di dunia digital.{" "}
          </p>
        </div>
        <div className="bg-bgManfaat bg-cover bg-no-repeat relative md:bottom-16 bottom-10 z-0 w-full md:pb-40 pb-10">
          <div className="max-w-5xl mx-auto p-5 text-gray-800 md:text-justify">
            <h3 className="mx-auto md:text-3xl md:pt-44 pt-16 pb-5 text-sky-800 font-bold text-center">
              Ada beberapa manfaat website untuk bisnis, diantaranya :
            </h3>
            <ul>
              <div className="pb-5">
                <li className="md:text-[22px] py-1 font-semibold">
                  1.{" "}
                  <span className="text-sky-800">
                    Meningkatkan Kredibilitas
                  </span>
                </li>
                <li className="px-4 font-medium md:text-[19px] text-[13px] text-justify">
                  Cukup dengan melihat profil perusahaan dari website, customer
                  dapat melihat sendiri seperti apa gambaran perusahaan yang
                  Anda miliki.
                </li>
              </div>
              <div className="pb-5">
                <li className="md:text-[22px] py-1 font-semibold">
                  2.{" "}
                  <span className="text-sky-800 font-semibold">
                    Memudahkan Pencarian Informasi
                  </span>
                </li>
                <li className="px-5 font-medium md:text-[19px] text-[13px] text-justify">
                  Seseorang dapat dengan mudah mengakses internet baik melalui
                  komputer atau pc, smartphone, atau tablet dimana pun dan
                  kapanpun mereka berada tanpa perlu mendatangi kantor secara
                  langsung.
                </li>
              </div>
              <div className="pb-5">
                <li className="md:text-[22px] py-1 font-semibold">
                  3.{" "}
                  <span className="text-sky-800 font-semibold">
                    Menghemat Waktu dan Pengeluaran
                  </span>
                </li>
                <li className="px-5 font-medium md:text-[19px] text-[13px] text-justify">
                  Dengan menggunakan website, pelaku bisnis dapat memasukan
                  berbagai macam informasi yang biasanya diperlukan pelanggan
                  mengenai produk atau jasa yang perusahaan Anda miliki.
                </li>
              </div>
              <div className="pb-5">
                <li className="md:text-[22px] py-1 font-semibold">
                  4.{" "}
                  <span className="text-sky-800 font-semibold">
                    Memperluas Jaringan
                  </span>
                </li>
                <li className="px-5 font-medium md:text-[19px] text-[13px] text-justify">
                  Karena website dapat dengan mudah diakses dari mana pun dan
                  kapan pun, hal ini membuat profil perusahaan Anda sebagai
                  pelaku bisnis diuntungkan.
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
