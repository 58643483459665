import React, { useEffect } from "react";
import '../App.css';
import Service from "./Service";
import Header from "./Header";
import Sertification from "./Sertification";
import Testimoni from "./Testimoni";
import Navbar from "./Navbar";
// import components

// Import Components
import Portofolio from "./Portofolio";
import AboutUs from "./AboutUs";
import Clients from "./Clients";
import Footer from "./Footer";

const Main = () => {
  useEffect(() => {
    document.title = "Digitalisasikan Bisnis Anda!";
  });

  return (
    <div className="w-full max-w-[1560px] mx-auto bg-white">
      <Navbar />
      <Header />
      <Sertification />
      <Service />
      <Portofolio />
      <AboutUs />
      <Testimoni />
      <div>
        <Clients />
        <Footer />
      </div>
    </div>      
  );
};

export default Main;
