import React from 'react'
import {tahapanmobile} from '../../data'
import agile from '../../assets/agile.png'
const TahapanMobile = ()=> {
    const{title,text1,text2,text3}= tahapanmobile;
  return (
    <section className='metode w-full bg-white'>
      <div className='container mx-auto'>
        <h2 className='title mx-auto md:text-5xl md:py-0 py-5 text-sky-800 font-bold text-xl text-center'>{title}</h2>
        <img className='w-[300px] lg:w-[780px] mx-auto' src={agile} alt='tahapan-pengembangan-web'/>
        <p className="md:text-[18px] md:leading-7 leading-5 text-xs text-center my-2 text-gray-800 font-medium">
            {text1}
        </p>
        <p className="md:text-[18px] md:leading-7 leading-5 text-xs text-center my-2 text-gray-800 font-medium"><span className="text-sky-700 font-semibold">{text2}</span>
              {text3}</p>
              
            
      </div>
    </section>
  )
}

export default TahapanMobile