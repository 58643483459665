import React from 'react'
import {biayamob} from '../../data'
const Biaya = ()=> {
    const{title,text1,text2,text3}= biayamob;
  return (
    <section className='biaya  w-full bg-white py-5'>
      <div className='container mx-auto '>
        <h2 className='title text-center font-bold  md:text-4xl py-4 text-sky-800 text-2xl   lg:leading-tight lg:max-w-[888px] mx-auto z-[1] mb-[0px] relative'>{title}</h2>
        <p className="md:text-[18px] md:leading-7 leading-5 text-xs text-center my-2 text-gray-800 font-medium">
            {text1}
        </p>
        <p className="md:text-[18px] md:leading-7 leading-5 text-xs text-center my-2 text-gray-800 font-medium"><span className="text-sky-700 font-semibold">{text2}</span>
              </p>
        <a href="whatsapp://send?text=Hello&phone=+6282152187313"><div className='cursor-pointer  rounded-2xl w-[300px] mx-auto mt-[20px] lg:w-[800px] h-[60px] lg:h-[45px] text-center py-[10px] bg-grey-7'><p className="md:text-[18px] md:leading-7 leading-5 text-xs text-gray-800 font-medium"><span className="text-sky-700 font-semibold">{text3}</span>
              </p></div></a>
      </div>
    </section>
  )
}

export default Biaya