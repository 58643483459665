import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';
// import data
import { layanan } from '../data';
import stats from '../assets/wave2.svg';
import stats2 from '../assets/wave3.svg';
const Service = () => {
  const { image1,image2,image3,title1,title2,title3,subtitle1,subtitle2,subtitle3,title} = layanan;
  return (
    <section name='layanan' id='layanan' className='layanan mx-auto lg:h-[100vh]  w-full bg-layanan bg-right lg:bg-center bg-cover bg-no-repeat pt-[20px] lg:pt-[100px]  relative '>
        <img className='w-full overflow-hidden absolute m-auto left-0 right-0 top-0'src={stats} alt ='no internet connection'/>
        <img className='w-full overflow-hidden absolute m-auto left-0 right-0 bottom-0'src={stats2} alt ='no internet connection'/>
        <h2 className='title text-center font-bold md:text-5xl py-4 text-sky-800 text-4xl   lg:leading-tight lg:max-w-[888px] mx-auto z-[1] mb-[80px] relative'>{title}</h2>
        <div className='container max-w-[1440px] m-auto  relative'>
          
              <div className='grid lg:grid-cols-3 lg:gap-[30px] m-auto lg:w-[1000px]'>
                    <div className='w-full max-w-[280px] h-[360px] text-left mx-auto cursor-pointer '>
                      <Link to="/mobile#headermobile" duration={500}>
                      <div className='bg-white border lg:border-[3px] border-grey-5 rounded-[20px] lg:rounded-[30px]'>
                          <div className='p-6'>
                            <img className='w-[80px] lg:w-[100px] mt-[-4.7rem]' src={image1.type} alt=''/>
                            <h3 className='font-bold text-[17px] text-primary my-6'>{title1}</h3>
                            <p className='text-gray-600 text-[15px]'>{subtitle1}</p>
                          </div>
                          <div className='bg-wave1 py-10 rounded-b-[20px] lg:rounded-b-[30px]'>
                              
                          </div>
                      </div>
                      </Link>
                    </div>
                    <div className='w-full max-w-[280px] h-[380px] text-left mx-auto cursor-pointer mt-[50px] lg:mt-[0px]'>
                      <Link to="/web#headerweb" duration={500}>
                      <div className='bg-white border lg:border-[3px] border-grey-5 rounded-[20px] lg:rounded-[30px]'>
                          <div className='p-6'>
                            <img className='w-[80px] lg:w-[100px] mt-[-4.7rem]' src={image2.type} alt=''/>
                            <h3 className='font-bold text-[17px] text-tosca my-6'>{title2}</h3>
                            <p className='text-gray-600 text-[15px]'>{subtitle2}</p>
                          </div>
                          <div className='bg-wave2 py-10 rounded-b-[20px] lg:rounded-b-[30px]'>
                             
                          </div>
                      </div>
                      </Link>
                    </div>
                    <div className='w-full max-w-[280px] h-[360px] text-left mx-auto mt-[50px] lg:mt-[0px] cursor-pointer'>
                    <Link to="/konsultasi" duration={500}>
                      <div className='bg-white border lg:border-[3px] border-grey-5 rounded-[20px] lg:rounded-[30px]'>
                          <div className='p-6'>
                            <img className='w-[80px] lg:w-[100px] mt-[-4.7rem]' src={image3.type} alt=''/>
                            <h3 className='font-bold text-[17px] text-orange my-6'>{title3}</h3>
                            <p className='text-gray-600 text-[15px]'>{subtitle3}</p>
                          </div>
                          <div className='bg-wave3 py-10 rounded-b-[20px] lg:rounded-b-[30px]'>
                          </div>
                      </div>
                      </Link>
                    </div>
              </div>
        </div>
      
    </section>
  );
};

export default Service;
