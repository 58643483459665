import React, { useEffect } from "react";
import Konsul from "./konsultasi IT/Konsul";
import Navbar from "./konsultasi IT/Navbar";


const KonsultasiIT = () => {
  useEffect(() => {
    document.title = "Digitalisasikan Bisnis Anda!";
  });

  return (
    <div className="w-full max-w-[1560px] mx-auto bg-white">
      <Navbar />
      <Konsul />
    </div>
  );
};

export default KonsultasiIT;
