import React from "react";
import { testimoni } from "../data";
// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";
// import swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
function Testimoni() {
  const { title, persons } = testimoni;
  return (
    <section
      name="testimoni"
      className="testimoni mx-auto w-full lg:w-[1000px] pt-[15px] lg:pt-[40px]  relative "
    >
      <h2 className="md:text-5xl py-5 text-sky-800 font-bold text-4xl text-center">
        {title}
      </h2>
      <Swiper
        pagination={{
          clickable: true,
        }}
        loop={true}
        autoplay={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 18,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }}
        modules={[Pagination, Autoplay]}
        className="testimoniSlider transition-all min-h-[400px]"
      >
        {persons.map((person, index) => {
          const { avatar, name, message } = person;
          return (
            <SwiperSlide key={index}>
              <div className="relative m-auto ">
                <div
                  className="w-full max-w-[280px] h-[300px] text-left mx-auto "
                  key={index}
                >
                  <div className="bg-white border lg:border-[3px] border-primary rounded-[20px] lg:rounded-[20px]">
                    <div className="p-6">
                      <p className="text-gray-600 text-[15px]">{message}</p>
                      <div className="flex items-center gap-x-4 ">
                        {/* avatar */}
                        <div className="bg-white border lg:border-[3px] border-grey-5 rounded-full mt-[10px]">
                          <img
                            className="w-[70px] h-[70px] rounded-full"
                            src={avatar.type}
                            alt=""
                          />
                        </div>
                        <div className="text-[16px] font-semibold">{name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}

export default Testimoni;
