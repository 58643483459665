// Export Logos from data
import { ClientsLogo } from "../data";

import { Navigation, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Clients = () => {
  const { logos } = ClientsLogo;

  return (
    <div className="max-w-5xl mx-auto pt-8 py-5">
      <h2 className="md:text-4xl text-sky-800 font-bold text-3xl text-center">
        Klien Kami
      </h2>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Autoplay]}
        navigation
        loop={true}
        autoplay={true}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
        className=""
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 0,
            autoplay: {
                delay:5000
            }
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 4,
          },
        }}
      >
        {logos.map((logo, index) => {
          const { url } = logo;
          return (
            <SwiperSlide key={index}>
              <div className="relative">
                <img className='w-[114px] lg:w-[235px] mx-auto'
                  src={url}
                  alt=""
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Clients;
